import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import Icon from "../custom-widgets/icon";

const LargePersonalizationCards = (props) => {
  const imgData = useStaticQuery(graphql`
    {
      card1AImageVar: file(relativePath: { eq: "cards/personalization/thumbnail-greenlight-092424.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 550
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      card2AImageVar: file(relativePath: { eq: "cards/personalization/thumbnail-business-banking-092524.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 550
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
    }
  `);

  /*
    NOTE: headings (Title) should be limited to 50 characters, and text (description) limited to 95 characters.
    as per design in Figma requirements.
  */
  const defaultCardData = [
    {
      // CARD SLOT 1
      slotNumber: 1,
      cards: [
        {
          cardNumber: 1,
          id: "home-large-personalization-card-1-default",
          imgData: imgData.card1AImageVar.childImageSharp.gatsbyImageData,
          altText: "Teen girl on her cell phone.",
          heading: "'Tis the season for helping kids get money smart with Greenlight!",
          subheading: null,
          text: "Help them learn personal finance, assign chores, send allowance, and more courtesy of WaFd Bank.",
          linkUrl: "/personal-banking/greenlight-kids-debit-card",
          btnText: "Get Greenlight"
        }
      ]
    },
    {
      // CARD SLOT 2
      slotNumber: 2,
      cards: [
        // Default - Business Banking
        {
          cardNumber: 1,
          id: "home-large-personalization-card-2-default",
          imgData: imgData.card2AImageVar.childImageSharp.gatsbyImageData,
          altText: "Business owner and employee looking at a tablet.",
          heading: "Find a business banking partner in WaFd Bank",
          text: "With personalized tools and support, WaFd is here to help your business succeed.",
          btnText: "Find Tools & Services",
          linkUrl: "/business-banking/small-business"
        },
        // Nevada - Business Clients
        {
          cardNumber: 2,
          id: "home-large-personalization-card-2-business-clients",
          imgData: null,
          heading: "FUELING SUCCESS TOGETHER: Your support helps WaFd's business clients thrive in their communities!",
          text: null,
          linkUrl: null,
          customContent: (
            <>
              <div className="row align-items-center mb-4">
                <div className="col-4">
                  <StaticImage
                    src="../../images/third-party/nevada-terribles-logo.jpg"
                    alt="Terrible's Herbst Logo."
                    placeholder="blurred"
                    quality="100"
                    className="w-100 border-radius-0"
                  />
                </div>
                <div className="col-8">
                  <h3>Terrible's Herbst</h3>
                  <h5 className="mb-0">
                    <a
                      href="https://www.terribles.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      id="nevada-card-link-1"
                    >
                      Visit Terrible's Herbst
                      <Icon name="external-link-alt" lib="far" class="ml-2" />
                    </a>
                  </h5>
                </div>
              </div>
              <div className="row align-items-center mb-4">
                <div className="col-4">
                  <StaticImage
                    src="../../images/third-party/nevada-bonanza-gift-shops-logo.jpg"
                    alt="Bonanza Gift Shop Logo."
                    placeholder="blurred"
                    quality="100"
                    className="w-100"
                  />
                </div>
                <div className="col-8">
                  <h3>Bonanza Gift Shop</h3>
                  <h5 className="mb-0">
                    <a
                      href="https://bonanza-gift-shop.weeblyte.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      id="nevada-card-link-2"
                    >
                      Visit Bonanza Gift Shop
                      <Icon name="external-link-alt" lib="far" class="ml-2" />
                    </a>
                  </h5>
                </div>
              </div>
              <div className="row align-items-center mb-4">
                <div className="col-4">
                  <StaticImage
                    src="../../images/third-party/nevada-doral-academy-logo.jpg"
                    alt="Doral Academy Logo."
                    placeholder="blurred"
                    quality="100"
                    className="w-100"
                  />
                </div>
                <div className="col-8">
                  <h3>Doral Academy</h3>
                  <h5 className="mb-0">
                    <a
                      href="https://www.doralacademynv.org"
                      target="_blank"
                      rel="noopener noreferrer"
                      id="nevada-card-link-3"
                    >
                      Visit Doral Academy
                      <Icon name="external-link-alt" lib="far" class="ml-2" />
                    </a>
                  </h5>
                </div>
              </div>
            </>
          )
        },
        // Idaho - Business Clients
        {
          cardNumber: 2,
          id: "home-large-personalization-card-2-business-clients-idaho",
          imgData: null,
          heading: "FUELING SUCCESS TOGETHER: Your support helps WaFd's business clients thrive in their communities!",
          text: null,
          linkUrl: null,
          customContent: (
            <>
              <div className="row align-items-center mb-4">
                <div className="col-4">
                  <img src="/images/idaho-the-yard-butler-logo.jpg" alt="The Yard Butler Logo." className="w-100" />
                </div>
                <div className="col-8">
                  <h3>The Yard Butler</h3>
                  <h5 className="mb-0">
                    <a
                      href="https://theyardbutler.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      id="idaho-card-link-1"
                    >
                      Visit The Yard Butler
                      <Icon name="external-link-alt" lib="far" class="ml-2" />
                    </a>
                  </h5>
                </div>
              </div>
              <div className="row align-items-center mb-4">
                <div className="col-4">
                  <img src="/images/idaho-tates-rents-logo.jpg" alt="Tates Rents Logo." className="w-100" />
                </div>
                <div className="col-8">
                  <h3>Tates Rents</h3>
                  <h5 className="mb-0">
                    <a
                      href="https://www.tatesrents.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      id="nevada-card-link-2"
                    >
                      Visit Tates Rents
                      <Icon name="external-link-alt" lib="far" class="ml-2" />
                    </a>
                  </h5>
                </div>
              </div>
              <div className="row align-items-center mb-4">
                <div className="col-4">
                  <img
                    src="images/idaho-titanium-excavation-logo.jpg"
                    alt="Titanium Excavation Logo."
                    className="w-100"
                  />
                </div>
                <div className="col-8">
                  <h3>Titanium Excavation</h3>
                  <h5 className="mb-0">
                    <a
                      href="https://www.titaniumexcavation.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      id="nevada-card-link-3"
                    >
                      Visit Titanium Excavation
                      <Icon name="external-link-alt" lib="far" class="ml-2" />
                    </a>
                  </h5>
                </div>
              </div>
            </>
          )
        }
      ]
    }
  ];
  // Use Props cardData if found, else use the default (used here due to imageVariable being used on the cards).
  const cardData = props.cardData || defaultCardData;
  const btnText = props.lang === "es" ? "Conoce más" : "Learn More";

  // NOTE: the slotCards and showHideClass logic below is for local build testing only, we can remove this show/hide logic once Tealium is handling that.
  const slotCards = props.defaultCardsArray; // show the defaults for now
  // defaultCardsArray picks the first card from each slot i.e., [1,1]

  /*
   * NOTE: please do NOT change the margin-bottom classes on these cards, it should be "mb-3 mb-lg-4".
   * Do NOT override the spacing using padding or margin classes on the props.containerClass.
   * If you need to override the section padding because the cards are connected
   * to other sections that need to look like one section, pass the sectionClass
   * "section-padding-top", "section-padding-none", "section-padding-bottom"
   */
  return (
    <div id="homepage-large-personalization-cards" className={`border-0 ${props.containerClass}`}>
      <div className="container">
        <ul className="row mb-0 pl-0 list-unstyled row-cols-1 row-cols-md-2">
          {cardData.map((slot, slotIndex) => {
            return (
              <li className="col-md mb-3 mb-lg-4 " key={slot.slotNumber}>
                <div id={`home-large-personalization-card-slot-${slot.slotNumber}`} className="h-100 ">
                  {slot.cards.map((card, cardIndex) => {
                    let showHideClass =
                      slotIndex + 1 === slot.slotNumber && card.cardNumber === slotCards[slotIndex]
                        ? "d-flex"
                        : "d-none";
                    return (
                      <div
                        id={card.id}
                        key={cardIndex}
                        tabIndex={0}
                        className={`card h-100 overflow-hidden border-radius-12 position-relative border-0 ${showHideClass} `}
                      >
                        {card.imgData && (
                          <GatsbyImage
                            className={`border-radius-12 border-radius-bottom-0`}
                            image={card.imgData}
                            alt={card.altText}
                          />
                        )}
                        <div className="card-body pb-0">
                          {card.heading && (
                            <h2 className="text-success font-weight-semi-bold" style={{ textDecoration: "none" }}>
                              {card.heading}
                            </h2>
                          )}
                          {card.text && <h4 dangerouslySetInnerHTML={{ __html: card.text }} />}
                          {card.customContent && <div id="card-custom-content">{card.customContent}</div>}
                        </div>
                        {card.linkUrl && (
                          <div className="card-footer bg-white border-0 border-radius-bottom-12">
                            <div className="row">
                              <div className="col-sm-8 col-md-12 col-lg-6 position-static">
                                <Link
                                  id={`homepage-large-card-${card.cardNumber}-btn`}
                                  className="btn btn-primary w-100 stretched-link"
                                  to={card.linkUrl}
                                >
                                  {card.btnText || btnText}
                                </Link>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default LargePersonalizationCards;
LargePersonalizationCards.defaultProps = {
  containerClass: "bg-info",
  defaultCardsArray: [1, 1],
  cardData: null,
  lang: "en"
};
